import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store";
import LoginView from "../views/LoginView.vue";

Vue.use(VueRouter);

const routes = [{
    path: "/",
    name: "LoginView",
    component: LoginView,
  },
  {
    path: "/proof/:urlcode",
    name: "PreviewView",
    component: () =>
      import(
        /* webpackChunkName: "proof" */
        "../views/PreviewView.vue"
      ),
  },
  {
    path: "/pdf/:urlcode",
    name: "PDFView",
    component: () =>
      import(
        /* webpackChunkName: "pdf" */
        "../views/PDFView.vue"
      ),
  },
  {
    path: "/list",
    name: "ProjectsView",
    component: () =>
      import(
        /* webpackChunkName: "projects" */
        "../views/ProjectsView.vue"
      ),
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () =>
      import(
        /* webpackChunkName: "notfound" */
        "../views/PageNotFound.vue"
      ),
  },
];

const router = new VueRouter({
  mode: "history",
  //base: process.env.BASE_URL,
  base: "/",
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

// router.beforeEach((to, from, next) => {
//   if (to.name !== "LoginView" && !store.state.user.isLogged) {
//     next({
//       path: "/",
//       replace: true,
//     });
//   } else {
//     next();
//   }
// });

export default router;