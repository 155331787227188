<template>
  <section class="hero is-fullheight loginbg">
    <b-loading
      :is-full-page="true"
      :active.sync="isLoading"
      :can-cancel="false"
    ></b-loading>
    <div class="center-grid">
      <div>
        <div class="grid-center">        <div class="logo-container">
              <div class="has-text-centered is-size-3 has-text-light mb-4">
                <div class="has-text-centered mb-2">
                  <img
                    :src="$env.customAssetsURL+ 'user_logo.svg'"
                    :alt="$env.siteTitle"
                    width="100%"
                  />
                </div>
              </div>
        </div>
        <div class="forms-container">
          <FindProof/>
              <div
                class="box animated mt-2"
                v-bind:class="{
                  shake: form.error,
                  intensifies: form.explode,
                  red: form.explode,
                }"
                @keyup.enter.exact="onLogin()"
              >
                <b-field
                  label="Email"
                  :type="form.email.status"
                  :message="form.email.message"
                >
                  <b-input
                    icon="envelope"
                    type="email"
                    v-model="form.email.data"
                    placeholder="Your email"
                  ></b-input>
                </b-field>
                <b-field
                  label="Password"
                  :type="form.pass.status"
                  :message="form.pass.message"
                >
                  <b-input
                    icon="lock-keyhole"
                    type="password"
                    v-model="form.pass.data"
                    password-reveal
                    placeholder="Your password"
                    required
                  ></b-input>
                </b-field>

                <b-message :type="form.status" v-if="form.result">
                  {{ form.message }}
                </b-message>

                <div class="field has-text-centered">
                  <button class="button is-pmmain" @click="onLogin()">
                    Login
                  </button>
                </div>
              </div>
          </div></div>

          
          <div class="has-text-centered mt-6">
                <small class="has-text-ddlight"
                  >{{ currentYear }} &copy;  {{ $env.footerText }}
                </small
                >
          </div>
        </div>
         

    </div>
  </section>
</template>

<script>
import FindProof from "../components/FindProof.vue";

export default {
  name: "LoginView",
  components: {
    FindProof,
  },
  data() {
    return {
      form: {
        code: {
          data: "",
          status: "",
          message: "",
        },
        email: {
          data: "",
          status: "",
          message: "",
        },
        pass: {
          data: "",
          status: "",
          message: "",
        },
        visible: true,
        message: "",
        result: false,
        status: "",
        countErrors: 0,
      },
      isLoading: false,
      currentYear: new Date().getFullYear(),
    };
  },
  methods: {
    onLogin() {
      if (this.emailCheck("email") & this.passCheck("pass")) {
        //this.form.visible = false;
        this.isLoading = true;
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/login.php`, {
            email: this.form.email.data,
            pass: this.form.pass.data,
          })
          .then((response) => {
            this.isLoading = false;
            this.form.result = true;
            this.form.message = response.data.message;
            console.log(response.data);
            if (response.data.success) {
              this.form.status = "is-success";
              this.$store.commit("loginUser", response.data);
              //!!!redirect
              this.$router.push({ path: "/list" }).catch((err) => {
                console.log(err);
              });
            } else {
              this.form.status = "is-danger";
              // this.hasError();
              //this.form.countErrors++;
              if (response.data.type == "email") {
                this.form.email.status = "is-danger";
              } else if (response.data.type == "pass") {
                this.form.pass.status = "is-danger";
              } else {
                this.form.email.status = "is-danger";
                this.form.pass.status = "is-danger";
              }
            }
          })
          .catch((error) => {
            this.isLoading = false;
            console.log(error);
            //this.hasError();
          });
        //end post-------------------------------------------------
      } else {
        this.form.status = "is-danger";
        this.form.result = true;
        this.form.message = "Ooops! Something went wrong. Please try again.";
      }
    },
    emailCheck(e) {
      let re =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line
      if (this.form[e].data === "" || !re.test(this.form[e].data)) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please enter a valid email address";
        return false;
      } else {
        this.form.email.status = "is-success";
        return true;
      }
    },
    passCheck(e) {
      if (this.form[e].data === "") {
        this.form[e].message = "Please enter a Password";
        this.form[e].status = "is-danger";
        return false;
      } else {
        this.form[e].message = null;
        this.form[e].status = "is-success";
        return true;
      }
    },
    formCheck(e) {
      if (!this.form[e].data) {
        this.form[e].status = "is-danger";
        this.form[e].message = "Please fill out all required fields";
        return false;
      } else {
        this.form[e].status = "is-success";
        return true;
      }
    },
    hasError() {
      if (this.form.countErrors > 2) {
        this.hasMultipleErrors();
        this.form.countErrors = 0;
      } else {
        this.form.error = true;
        setTimeout(() => (this.form.error = false), 1500);
      }
      setTimeout(() => (this.form.submited = false), 1500);
    },
    hasMultipleErrors() {
      this.form.explode = true;
      setTimeout(() => (this.form.explode = false), 2000);
    },
  },
  metaInfo() {
    return {
      title: this.$env.siteTitle,
      titleTemplate: 'Login | %s',
      htmlAttrs: {
        lang: 'en'
      }
    };
  },
};
</script>
<style scoped>
@import "~@/static/intensifies.css";
.red {
  background: #e40000;
  transition: background 2s;
}
.center-grid{
  display: grid;
  place-items: center;
  height: 100vh;
}

.forms-container{
  width: 360px;
  align-items: center;
}

.grid-center{
  display:flex;
  align-items: center;
  flex-direction: column;
}

</style>
