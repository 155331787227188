import Vue from "vue";
import Vuex from "vuex";
//import axios from "axios";

Vue.use(Vuex);
import _ from 'lodash';

export default new Vuex.Store({
  state: {
    viewerLinks: null,
    user: {
      isLogged: false,
      token: null,
      name: null,
      email: null,
      status: null,
    },
    modals: {
      proofDescription: false,
      approveProof: false,
      rejectProof: false,
      login: false,
    },
    proofID: null,
    sidebarShow: true,
  },
  mutations: {
    showModal(state, payload) {
      console.log("showModal: " + payload);
      state.modals[payload] = true;
    },
    hideModal(state, payload) {
      state.modals[payload] = false;
    },
    toggleModal(state, payload) {
      state.modals[payload] = !state[payload];
    },
    loginUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = payload.jwt;
      state.user.name = payload.name;
      state.user.email = payload.email;
      state.user.status = payload.status;

      //local
      localStorage.ddPVT = payload.jwt;
      localStorage.ddPVE = payload.email;
    },
    logoutUser(state) {
      state.user.isLogged = false;
      state.user.token = null;
      state.user.name = null;
      state.user.email = null;
      state.user.status = null;
      //local
      localStorage.removeItem("ddPVT");
      localStorage.removeItem("ddPVE");
    },
    validateUser(state, payload) {
      state.user.isLogged = true;
      state.user.token = localStorage.ddPVT;
      state.user.name = payload.name;
      state.user.email = payload.email;
      state.user.status = payload.status;
    },
    setViewerLinks(state, payload) {
      state.viewerLinks = payload;
    },
    unsetViewerLinks(state) {
      state.viewerLinks = null;
    },
    toggleSidebar(state) {
      state.sidebarShow = !state.sidebarShow;
    },
    hideSidebar(state) {
      state.sidebarShow = false;
    },
    showSidebar(state) {
      state.sidebarShow = true;
    },
    setProofID(state, payload) {
      state.proofID = payload;
    }
  },
  actions: {},
  modules: {},
});
