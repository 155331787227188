import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import Buefy from "buefy";
import VueDragResize from "vue-drag-resize";
import VueMeta from "vue-meta";

Vue.component("vue-drag-resize", VueDragResize);
Vue.config.productionTip = false;

Vue.prototype.$env = {
  siteTitle: "Proofing Monkey",
  siteURL: "https://demo.proofingmonkey.com/",
  shareImg: "/img/share.jpg",
  api: "https://demo.proofingmonkey.com/api_user",
  bleedsURL: "https://demo.proofingmonkey.com/bleeds/",
  proofsURL: "https://demo.proofingmonkey.com/proofs/",
  mapsURL: "https://demo.proofingmonkey.com/maps/",
  sceneAssetsURL: "https://demo.proofingmonkey.com/3dassets/",
  customAssetsURL: "https://demo.proofingmonkey.com/customassets/",
  footerText: "Proofing Monkey | All rights reserved.",
  primaryColor: "#00aaff",
};

Vue.use(Buefy, {
  defaultIconPack: "syntho",
});

Vue.use(VueAxios, axios);

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
