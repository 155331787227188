<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "app",
  data() {
    return {
      localToken: null,
    };
  },
  mounted() {
    console.log("APP MOUNTED!");
     console.log("Begin User Check!");
    if (!this.$store.state.user.isLogged) {
      this.checkLoged();
      console.log(this.$store.state.user.isLogged);
      //console.log(this.$route.name);
    }
  },
  methods: {
    checkLoged: function () {
      if (localStorage.ddPVT) {
        console.log("Validating Token..");
        console.log("Token:" + localStorage.ddPVT);
        //begin post --------------------------------------------
        this.axios
          .post(`${this.$env.api}/validate.php`, {
            token: localStorage.ddPVT,
          })
          .then((response) => {
            console.log("RESPONCE:" + JSON.stringify(response));
            if (response.data.success) {
              this.$store.commit("validateUser", response.data.data);
              console.log("Loged!");
              if (this.$route.name == "LoginView") {
                this.$router.push({ path: "/list" }).catch((err) => {
                  console.log(err);
                });
              }
              // this.$router.push({ path: "/list" }).catch((err) => {
              //   console.log(err);
              // });
              console.log(response.data.data);
            }else{
              console.log("Not Loged!");
            }
          })
          .catch((error) => {
            //this.isLoading = false;
            console.log(error);
          });
        //end post-------------------------------------------------
      } else {
        console.log("Not Loged!");
      }
    },
  },
};
</script>

<style lang="scss">
//Import Fonts
@import url("https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@400;600&family=Roboto:wght@100;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans+Condensed:wght@300;700&display=swap");
//font-family: 'Bebas Neue', cursive;
//font-family: 'Roboto', sans-serif;

// Import Fontawesome
//@import "static/fontawesome/css/all.min.css";
@import "static/syntho/style.css";
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
// Set your colors
$primary: #abb7c4;
// $success: #7cbc4b;
$pmmain: #002e46;
$pmblue: #002e46;
$accent: #ed6969;

:root {
  --main-color: #002e46;
  --accent-color: #ed6969;
}


// $modal-background-background-color: #000;
$primary-invert: findColorInvert($primary);
//theme mod
$mydark: #23262b; //#00bb8f; //00D1B2
$mylight: #ebebeb; //#00bb8f; //00D1B2
// $dark: #0d4553;
$filter: #f4f4f4; //#00bb8f; //00D1B2
$sidebar-box-shadow: 0px;
$sidebar-width: 330px;
// $body-background-color: #4a4f54;
$content-heading-color: #000000;

$pmportable: #ffcd0b;
$pmmodular: #f37422;
$pmcustom: #ed3a25;
$pmgraphic: #71cee3;
$pmproject: #b393b0;

$pmgray: #4a4f54;
$pmgray2: #41464c;
$pmgray3: #383c41;
$pmgray4: #2e3136;
$pmgray5: #23262b;

$pmlight: #97a3ae;
$pmlight2: #d8dfe1;
$pmlight3: #f7f7f7;

// $navbar-item-color: $pmgray!important;
// $navbar-dropdown-arrow: $pmgray!important;

$footer-background-color: $pmgray;
$footer-color: $pmlight2;
$footer-padding: 2rem 1.5rem 2rem;

//$family-primary: 'Poppins', sans-serif;
$family-primary: "Roboto", sans-serif;

$loading-background: rgba(255,255,255,1);

// Setup $colors to use as bulma classes (e.g. 'is-twitter')
$colors: (
  "white": (
    $white,
    $black,
  ),
  "black": (
    $black,
    $white,
  ),
  "light": (
    $light,
    $light-invert,
  ),
  "dark": (
    $dark,
    $dark-invert,
  ),
  "pmmain": (
    $pmmain,
    $white,
  ),
  "primary": (
    $primary,
    $primary-invert,
  ),
  "info": (
    $info,
    $info-invert,
  ),
  "success": (
    $success,
    $success-invert,
  ),
  "warning": (
    $warning,
    $warning-invert,
  ),
  "danger": (
    $danger,
    $danger-invert,
  ),
  "mydark": (
    $mydark,
    $white,
  ),
  "mylight": (
    $mylight,
    $black,
  ),
  "filter": (
    $filter,
    $white,
  ),
  "pmportable": (
    $pmportable,
    $white,
  ),
  "pmmodular": (
    $pmmodular,
    $white,
  ),
  "pmcustom": (
    $pmcustom,
    $white,
  ),
  "pmgraphic": (
    $pmgraphic,
    $white,
  ),
  "pmproject": (
    $pmproject,
    $white,
  ),
  "pmgray": (
    $pmgray,
    $white,
  ),
  "pmgray2": (
    $pmgray2,
    $white,
  ),
  "pmgray3": (
    $pmgray3,
    $white,
  ),
  "pmgray4": (
    $pmgray4,
    $white,
  ),
  "pmgray5": (
    $pmgray5,
    $white,
  ),
  "pmlight": (
    $pmlight,
    $white,
  ),
  "pmlight2": (
    $pmlight2,
    $pmgray,
  ),
);

.is-bebas {
  font-family: "Bebas Neue", cursive;
  line-height: 1;
  text-align: center;
  font-size: 1.6rem;
}
.is-pop {
  font-family: "Poppins", sans-serif;
  font-weight: 600 !important;
  text-transform: uppercase;
  font-size: 14px;
}

// .button {
//   font-family: "Poppins", sans-serif;
//   text-transform: uppercase;
//   font-weight: 600 !important;
// }

// Links
$link: $primary;
$link-invert: $primary-invert;
$link-focus-border: $primary;

$input-icon-color: $pmlight;
$input-icon-active-color: $pmgray;

.sidebar-button {
  position: fixed;
  top: 25px;
  right: 16px;
  z-index: 40;
  cursor: pointer;
  display: none;
}

@media screen and (max-width: 1023px) {
  .sidebar-button {
    display: unset;
  }
}

.navbar-item {
  color: #b3bcc5 !important;
  font-family: "Poppins", sans-serif;
  font-weight: 400 !important;
  text-transform: uppercase;
  font-size: 14px;
}

.pmtitle {
  font-size: 3rem;
  font-family: "Bebas Neue";
  color: $pmmain !important;
  line-height: 1;
}

// .menu {
//   font-family: "Poppins", sans-serif;
//   text-transform: uppercase;
//   font-size: 0.9em !important;
// }

//nav icon
#nav-icon3 {
  width: 25px;
  height: 30px;
  position: relative;
  // margin: 50px auto;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.5s ease-in-out;
  -moz-transition: 0.5s ease-in-out;
  -o-transition: 0.5s ease-in-out;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

#nav-icon3 span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  background: #7cbc4b;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: 0.25s ease-in-out;
  -moz-transition: 0.25s ease-in-out;
  -o-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}

#nav-icon3 span:nth-child(1) {
  top: 0px;
}

#nav-icon3 span:nth-child(2),
#nav-icon3 span:nth-child(3) {
  top: 7px;
}

#nav-icon3 span:nth-child(4) {
  top: 14px;
}

#nav-icon3.open span:nth-child(1) {
  top: 7px;
  width: 0%;
  left: 50%;
}

#nav-icon3.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#nav-icon3.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#nav-icon3.open span:nth-child(4) {
  top: 7px;
  width: 0%;
  left: 50%;
}

.header-title {
  color: #fff;
  font-size: 4rem;
  font-family: "Bebas Neue";
}
.header-subtitle {
  color: #fff;
  font-size: 2rem;
}
//end nav icon

$card-header-background-color: $primary;

.card-header-image {
  background-color: #0f92ff !important;
}

.card-header-stats {
  background-color: #0f92ff !important;
}

// .card-header-actions{
//   background-color: #0f92ff!important;
// }

$card-header-color: $pmlight3;

$card-header-padding: 30px 20px;

.card-header-title {
  font-size: 2.3rem;
  font-family: "Bebas Neue", cursive;
  text-transform: uppercase;
  font-weight: 100 !important;
}

.card-footer {
  padding: 20px;
  flex-shrink: 0;
  justify-content: flex-end;
}

.card-footer-sample {
  padding: 0px !important;
  flex-shrink: unset;
  justify-content: unset;
}

.card-footer .button:not(:last-child) {
  margin-right: 0.5em;
}

.modal-card-title {
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-weight: 600 !important;
}
// .content li + li {
//      margin-top: 0em!important;
// }

// .content ul {
//     margin-left: 0em!important;
//     margin-top: 0em!important;
// }

.main {
  padding-top: 40px;
  min-height: calc(100vh - 128px);
}

/*mobile braking point */
@media screen and (max-width: 1023px) {
  .main {
    padding-top: 0px;
    min-height: calc(100vh - 128px);
  }
}

.loginbg {
  background: #f7f7f7;
}

.link-bt {
  cursor: pointer;
}
.link-red {
  color: #ed3a25;
}
.link-green {
  color: #7cbc4b;
}

.icon-single {
  color: #3e8ed0;
}
.icon-group {
  color: #f14668;
}

.link-bt {
  cursor: pointer;
}
.link-red {
  color: #ed3a25;
}
.link-green {
  color: #7cbc4b;
}

.icon-single {
  color: #3e8ed0;
}
.icon-group {
  color: #f14668;
}

.status-ico{
display: flex;
flex-direction: row;
justify-content: space-arround;
align-items: center;
font-size: 1.2em;
opacity: 0.5;
}

.status-text{
  font-size: 1.3em;
  font-family: Bebas Neue,cursive;
  text-transform: uppercase;
  font-weight: 100!important;
  margin-top: 3px;
}

.status-ico:hover{
  opacity: 1;
}

.ico-red {
  color: #f14668;
}
.ico-green {
  color: #7cbc4b;
}
.ico-blue {
  color: #3e8ed0;
}
.ico-orange {
  color: #ffa600;
}
.ico-gray {
  color: #4a4f54;
}

.red-bt,
.green-bt,
.oranj-bt,
.blue-bt {
  color: #c9c9c9;
  cursor: pointer;
  float: left;
  font-size: x-large;
}
.red-bt:hover {
  color: #f14668;
}
.green-bt:hover {
  color: #7cbc4b;
}
.blue-bt:hover {
  color: #3e8ed0;
}
.oranj-bt:hover {
  color: #ffa600;
}

.table {
  background-color: transparent !important;
}

.has-title {
  width: 100%;
  overflow: hidden;
}

.vdr-stick-tm,
.vdr-stick-bm,
.vdr-stick-ml,
.vdr-stick-mr {
  display: none !important;
}

.icon {
  //margin: 5px 5px 0px 0px;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.grid:before {
  content: "" !important;
}

.image:before {
  content: "" !important;
}

.icon.is-large {
  height: 3rem;
  width: 3rem;
  font-size: 3rem;
}

.not-clickable {
  pointer-events: none;
}
.not-selectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.clickable {
  cursor: pointer;
}

.dashboard-box {
  color: #7a7a7a;
  cursor: pointer;
}

.dashboard-box:hover .el-color {
  color: #fff;
}

.t-num {
  font-weight: 700;
  font-size: 1.5rem;
}

.t-total {
  font-size: 1rem;
}

.box-bt {
  text-align: center;
  color: #7cbc4b !important;
  font-size: 1.3rem;
  font-weight: 700;
  cursor: pointer;
}

.box-bt:hover {
  color: #ffffff !important;
  background-color: #7cbc4b;
}

.box-signed .el-color {
  color: #7cbc4b;
}

.box-signed:hover {
  background-color: #7cbc4b;
  color: #fff;
}

.box-rejected .el-color {
  color: #f14668;
}

.box-rejected:hover {
  background-color: #f14668;
  color: #fff;
}

.box-notsigned .el-color {
  color: #3e8ed0;
}

.box-notsigned:hover {
  background-color: #3e8ed0;
  color: #fff;
}

.box-archived .el-color {
  color: #4a4f54;
}

.box-archived:hover {
  background-color: #4a4f54;
  color: #fff;
}

.card-header-signed {
  background-color: #74b570 !important;
}

.card-header-notsigned {
  background-color: #3e8ed0 !important;
}

.card-header-rejected {
  background-color: #f14668 !important;
}

.card-header-archived {
  background-color: #002e46 !important;
}

.label{
  text-transform: uppercase;
  color: #97a3ae!important;
  font-weight: 400!important;
}

.button{
  text-transform: uppercase;
  touch-action: manipulation;
}

// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
</style>
